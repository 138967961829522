/**
 * @function normalizeGuest
 * @description normalize guest list data
 * @returns {Array<wish>}
 */
function normalizeGuest(guests = []) {
  if (!guests) return [];

  return guests.map((item) => {
    return {
      code: item.CODE || '',
      name: item.NAME || name || '',
      desc: item.DESC || '',
      shift: item.SHIFT || '',
      country_code: item.COUNTRY_CODE || '',
      address: item.ADDRESS || '',
      phone_number: item.PHONE_NUMBER || '',
      email: item.EMAIL || '',
      guest_quota: item.GUEST_QUOTA || 0,
      guest_confirm: item.GUEST_CONFIRM || 0,
      guest_lift: item.GUEST_LEFT || 0,
      status: item.STATUS || '',
      attend_info: item.ATTEND_INFO || '',
      system_info: item.SYSTEM_INFO || '',
      last_update: item.LAST_UPDATE || '',
    };
  });
}

export default normalizeGuest;
