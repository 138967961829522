import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

function ModalInvitation({ onClose, visible }) {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent bgColor="#F0EDE6">
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="Newsreader"
          borderBottomWidth="1px"
          borderBottomColor="black"
          fontSize="xl"
          fontWeight="bold"
          color="black"
          textAlign="left"
        >
          Apa itu Link Invitation?
        </DrawerHeader>
        <DrawerBody>
          <Text align="left" fontSize="md" fontFamily="Newsreader" margin="16px 0" color="black">
            <b>Link Invitation</b> adalah sebuah website dengan informasi terlengkap dimana link ini
            ditujukan kepada tamu undangan yang dikehendaki untuk hadir ke acara secara langsung.
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalInvitation.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func,
};

export default ModalInvitation;
