import { TYPE_BROADCAST } from './types';
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Berkaitan dengan salah satu pemberlakuan protokol kesehatan, yakni pembatasan jumlah tamu, kami memohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara langsung. 

Meski demikian, di masa pandemi saat ini hal terpenting adalah sama-sama saling menjaga agar kita semua selalu sehat dan senantiasa dalam lindungan Tuhan.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 
Mohon pengertiannya, terima kasih.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*
  `,
  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

*Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:*
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami, pada hari ini kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa secara langsung dalam acara kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}*
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}* di {{BROADCAST_WEDDING_LOCATION}}.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Mohon pengertiannya untuk segala protokol kesehatan yang berlaku. Semoga kita semua selalu dalam keadaan sehat. 

Sampai jumpa di acara pernikahan kami!

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*
    `,
  //Reminder RSVP

  [TYPE_BROADCAST.ID_REMINDER_RSVP]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melanjutkan undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, kami ingin mengingatkan Bapak/Ibu/Saudara/i untuk segera mengisi formulir konfirmasi kehadiran (RSVP) yang ada pada website undangan kami:
{{LINK}} 

Status kehadiran Bapak/Ibu/Saudara/i akan bermanfaat untuk kami dalam mempersiapkan acara pernikahan mendatang. 

Terima kasih atas perhatiannya.

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_
`,

  [TYPE_BROADCAST.EN_REMINDER_RSVP]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Following up on our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, we would like to remind you to immediately fill out the attendance confirmation form (RSVP) on our invitation website: 
{{LINK}}

Your attendance status will be very useful for us in preparing the upcoming wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

  [TYPE_BROADCAST.ID_REMINDER_EVENT]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melalui pesan ini, kami ingin mengingatkan kembali undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* yang diselenggarakan pada: 

Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}} 
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

Besar harapan kami Bapak/Ibu/Saudara/i berkenan menghadiri pernikahan kami.

Terima kasih 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,
  [TYPE_BROADCAST.EN_REMINDER_EVENT]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Through this message, we would like to remind you of our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}* which will be held on:

Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

We hope that you are willing to attend our wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

  [TYPE_BROADCAST.ID_THANKS]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* dan keluarga ingin mengucapkan terima kasih atas kesediaan Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami. 

Kehadiran Bapak/Ibu/Saudara/i tentunya telah menjadi kebahagiaan untuk kami & keluarga. Kami juga ingin memohon maaf jika selama berlangsungnya acara terdapat hal-hal yang kurang berkenan. 
 
Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_

`,

  [TYPE_BROADCAST.EN_THANKS]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

We, *{{BOY_NAME}}* & *{{GIRL_NAME}}* and family would like to thank you for your willingness to attend our wedding.

Your presence has certainly been a joy for us & our family. We also want to apologize if during the event there are things that are not pleasing.
 
Thank you for all the words, prayers, and attention given.

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,

  RSVP_CONFIRMATION: `_*DANIEL & PRISCILLA WEDDING*_

Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

Perkenalkan, kami dari *Invitato* mewakili keluarga pengantin ingin menyampaikan undangan pernikahan: 
  
*Daniel Hadi* 
Mr. Andreas Boerwan Hadi
& Mrs. Puspa Dewi Hendrawan

*Priscilla Setio*
Mr. Kusnadi Setio
& Mrs. Linda

Acara pernikahan akan diselenggarakan pada: 
*HOLY MATRIMONY*
Hari: Sabtu, 13 Januari 2024
Waktu: 09.55 WIB
Lokasi: Jakarta Cathedral
_Jl. Katedral No.7B, Ps. Baru, DKI Jakarta_

*RECEPTION*
Hari: Sabtu, 13 Januari 2024
Waktu: 18.00 WIB
Lokasi: Bali BallRoom, Hotel Kempinski Indonesa
_Jl. M.H. Thamrin No.1, Menteng, DKI Jakarta_

Silakan *konfirmasi kehadiran Anda* pada link RSVP undangan website di bawah ini:

{{LINK}}

_*Jika link tidak berwarna biru/tidak muncul silakan untuk coba membalas chat ini dengan "YA", atau simpan nomor ini untuk dapat membuka link.*_

Konfirmasi Bapak/Ibu/Saudara/i kami tunggu maksimal sampai tanggal *6 Januari 2024*. Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga. 

Terima kasih
  
*Kami yang berbahagia,*
Daniel, Priscilla & keluarga`,
  MANUAL_CONFIRMATION: `_*DANIEL & PRISCILLA WEDDING*_

Yth. Bapak/Ibu/Saudara/i, 
{{GUEST_NAME}}
 
Mewakili keluarga *Daniel Hadi & Priscilla Setio*, kami ingin menanyakan kembali terkait konfirmasi kehadiran Bapak/Ibu pada acara pernikahan *Daniel & Priscilla*.

Berikut kami kirimkan kembali link undangan website Bapak/Ibu/Saudara/I:

{{LINK}}

_*Jika link tidak berwarna biru/tidak muncul silakan untuk coba membalas chat ini dengan "YA", atau simpan nomor ini untuk dapat membuka link.*_

*KONFIRMASI KEHADIRAN:*
1. Apakah Bapak/Ibu/Saudara/i berkenan hadir di Holy Matrimony?
*Jawab:* 
Ya, hadir Holy Matrimony ... orang 
Tidak hadir
*Kuota total tamu Anda: {{GUEST_QUOTA}} orang*

2. Apakah Bapak/Ibu/Saudara/i berkenan hadir di Resepsi?
*Jawab:*
Ya, hadir Resepsi ... orang 
Tidak hadir
*Kuota total tamu Anda: {{GUEST_QUOTA}} orang*

Mohon segera konfirmasikan kehadiran Bapak/Ibu/Saudara/i maksimal *11 Januari 2024*.

Terima kasih ☺️🙏🏻`,

  RSVP_REMINDER_EVENT: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

Mewakili keluarga pengantin, kami dari *Invitato* ingin mengingatkan undangan pernikahan *Daniel Hadi & Priscilla Setio*.

Acara pernikahan akan diselenggarakan pada: 
*HOLY MATRIMONY*
Hari: Sabtu, 13 Januari 2024
Waktu: 09.55 WIB 
*) Dimohon hadir tepat waktu
Lokasi: Jakarta Cathedral
_Jl. Katedral No.7B, Ps. Baru, DKI Jakarta_

*RECEPTION*
Hari: Sabtu, 13 Januari 2024
Waktu: 18.00 WIB
*) Dimohon hadir tepat waktu
Lokasi: Bali BallRoom, Hotel Kempinski Indonesa
_Jl. M.H. Thamrin No.1, Menteng, DKI Jakarta_

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara dan berlaku untuk *{{GUEST_CONFIRM}} orang*.

*DRESSCODE:* 
- *Wanita (Formal Attire)*
Warna Putih/Biru/Coklat 
- *Pria (Formal Attire)* 
Warna Hitam untuk Jas 
Warna Putih/Biru/Coklat/Batik untuk Kemeja


Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Daniel & Priscilla.

_Kami yang berbahagia,_
*Daniel Hadi* 
Putra dari Andreas Boerwan Hadi
& Puspa Dewi Hendrawan
*Priscilla Setio*
Putri dari Kusnadi Setio
& Linda`,
  RSVP_REMINDER_EVENT_HOLMAT: `Yth. Bapak/Ibu/Saudara/i, 
  *{{GUEST_NAME}}*
  
Mewakili keluarga pengantin, kami dari *Invitato* ingin mengingatkan undangan pernikahan *Daniel Hadi & Priscilla Setio*.

Acara pernikahan akan diselenggarakan pada: 
*HOLY MATRIMONY*
Hari: Sabtu, 13 Januari 2024
Waktu: 09.55 WIB 
*) Dimohon hadir tepat waktu
Lokasi: Jakarta Cathedral
_Jl. Katedral No.7B, Ps. Baru, DKI Jakarta_

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara dan berlaku untuk *{{GUEST_CONFIRM}} orang*.

*DRESSCODE:* 
- *Wanita (Formal Attire)*
Warna Putih/Biru/Coklat 
- *Pria (Formal Attire)* 
Warna Hitam untuk Jas 
Warna Putih/Biru/Coklat/Batik untuk Kemeja


Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Daniel & Priscilla.

_Kami yang berbahagia,_
*Daniel Hadi* 
Putra dari Andreas Boerwan Hadi
& Puspa Dewi Hendrawan
*Priscilla Setio*
Putri dari Kusnadi Setio
& Linda`,
  RSVP_REMINDER_EVENT_RESEPSI: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

Mewakili keluarga pengantin, kami dari *Invitato* ingin mengingatkan undangan pernikahan *Daniel Hadi & Priscilla Setio*.

Acara pernikahan akan diselenggarakan pada: 
*RECEPTION*
Hari: Sabtu, 13 Januari 2024
Waktu: 18.00 WIB
*) Dimohon hadir tepat waktu
Lokasi: Bali BallRoom, Hotel Kempinski Indonesa
_Jl. M.H. Thamrin No.1, Menteng, DKI Jakarta_

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara dan berlaku untuk *{{GUEST_CONFIRM}} orang*.

*DRESSCODE:* 
- *Wanita (Formal Attire)*
Warna Putih/Biru/Coklat 
- *Pria (Formal Attire)* 
Warna Hitam untuk Jas 
Warna Putih/Biru/Coklat/Batik untuk Kemeja


Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Daniel & Priscilla.

_Kami yang berbahagia,_
*Daniel Hadi* 
Putra dari Andreas Boerwan Hadi
& Puspa Dewi Hendrawan
*Priscilla Setio*
Putri dari Kusnadi Setio
& Linda`,
};